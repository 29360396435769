import { mapHelper } from "@/utils/common.js";
const ageArr = [
  {
    label: "3岁及以下",
    value: "1",
  },
  {
    label: "4-17岁",
    value: "2",
  },
  {
    label: "18-59岁",
    value: "3",
  },
  {
    label: "60-69岁",
    value: "4",
  },
  {
    label: "70-79岁",
    value: "5",
  },
  {
    label: "80岁以上",
    value: "6",
  },
];
const { map: ageArrMap, setOps: ageArrOps } = mapHelper.setMap(ageArr);

const whether = [
  {
    label: "是",
    value: 1,
  },
  {
    label: "否",
    value: 0,
  },
];
const { map: whetherMap, setOps: setWhetherOps } = mapHelper.setMap(whether);

const formatText = (row, prop) => {
  let text = (['', null, void 0, NaN].includes(row[prop]) ? '' : row[prop]) + '';
  if ([''].includes(text.trim())) {
    text = '无';
  }
  return text;
}

export { ageArrMap, ageArrOps, whether, whetherMap, setWhetherOps, formatText };
