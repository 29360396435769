<template>
  <div class="opeDashboard-divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :isIndex="false"
      :isOperateColumn="false"
      :headers="headers"
      :tableUrl="tableUrl"
      :afterReturnList="afterReturnList"
      :searchParam.sync="searchParam"
      :cellStyle="
        ({ row, column, rowIndex, columnIndex }) =>
          row.exceptionFields?.split(',').includes(column.property)
            ? { backgroundColor: 'rgb(255,153,154)' }
            : column.label.includes('得分')
            ? { backgroundColor: 'rgba(166,240,183,0.5)' }
            : {}
      "
      :header-cell-style="({ row, column, rowIndex, columnIndex }) => (column.label.includes('得分') ? { backgroundColor: 'rgba(166,240,183,0.5)' } : {})"
    >
      <template #headBtnSlot>
        <v-button text="导出" :loading="exportLoading" @click="exportOnClick"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="未来社区名称" v-model="searchParam.tenantName" clearable />
        <v-select clearable :options="initTenantQxData" v-model="searchParam.tenantQx" @change="changeTenantQxData" label="区县" />
        <v-select
          v-if="searchParam.tenantQx"
          clearable
          :options="tenantJdData"
          v-model="searchParam.tenantJd"
          @change="$refs.list.search()"
          label="街道"
        />
        <v-select clearable :options="initYspcData" v-model="searchParam.yspc" @change="$refs.list.search()" label="验收批次" />
        <v-select clearable :options="whetherOptions" v-model="searchParam.hasException" @change="$refs.list.search()" label="是否有异常" />
      </template>
    </v-list>
    <v-dialog
      :title="serviceTitle"
      v-model="serviceFlag"
      :close-on-click-modal="false"
      class="editerV"
      @confirm="confirm"
      :showConfirmButton="false"
      :showCancelButton="false"
    >
      <div class="service">服务次数：{{ serviceTime }}次</div>
    </v-dialog>
    <v-dialog
      :title="zhuanData.zhuanTitle"
      v-model="zhuanData.zhuanFlag"
      :close-on-click-modal="false"
      class="editerZhuan"
      @confirm="confirm"
      :showConfirmButton="false"
      :showCancelButton="false"
    >
      <div class="item">
        <div class="item-l">图片：</div>
        <div class="item-r">
          <v-upload :imgUrls.sync="zhuanData.img" disabled />
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { getYysjListUrl, exportYysjUrl, getYysjWlsqmcOptUrl, initYspcUrl, initTenantQxUrl } from './api.js';
import { formatText, setWhetherOps } from './map.js';
import { createHTMLVNode } from '@/utils/utils.js';
let judgePercent = function (a, b) {
  if (a && b) {
    const numA = parseFloat(a.replace(/%/, ''));
    const numB = parseFloat(b.replace(/%/, ''));
    return numA - numB;
  }
};
export default {
  name: 'opeDashboardYysjList',
  data() {
    return {
      whetherOptions: setWhetherOps(),
      wlsqOptions: [],
      searchParam: {
        tenantName: undefined, // P1C2A2S1D1
        yspc: undefined,
        hasException: undefined,
        tenantQx: undefined,
        tenantJd: undefined
      },
      zhuanData: {
        zhuanTitle: '',
        zhuanFlag: false,
        content: '',
        img: '',
        file: ''
      },
      serviceTime: '',
      serviceTitle: '',
      serviceFlag: false,
      tableUrl: getYysjListUrl,
      // activePopulation,activeRate,averageClicks,averageActivePopulation,allRoomCount,tenantAllPersonCount,registerPersonCount,registerRoomCount,registerRoomMatchingRate
      headers: [
        {
          prop: '',
          label: '序号',
          width: '60px',
          align: 'center',
          formatter: (row, prop, index) => {
            return index + 1 + (this.searchParam.curPage - 1) * this.searchParam.pageSize;
          }
        },
        {
          prop: 'tenantName',
          label: '未来社区名称',
          minWidth: '160px',
          formatter: (row, prop) => {
            let oBtn;
            if (row.tenantName) {
              oBtn = `<span class="operaTxt">${row.tenantName}</span>`;
            } else {
              oBtn = `<span class="operaTxt">无</span>`;
            }
            return createHTMLVNode(this, oBtn, row, prop, {
              color: '#606266',
              cb: (e, row) => {}
            });
          }
        },
        {
          prop: 'yspc',
          label: '验收批次',
          minWidth: '160px',
          sortable: true,
          formatter: (row, prop) => {
            let oBtn;
            if (row.yspc) {
              oBtn = `<span class="operaTxt">${row.yspc}</span>`;
            } else {
              oBtn = `<span class="operaTxt">无</span>`;
            }
            return createHTMLVNode(this, oBtn, row, prop, {
              color: '#606266',
              cb: (e, row) => {}
            });
          }
        },
        {
          prop: 'tenantQx',
          label: '所在区县',
          minWidth: '160px',
          formatter: formatText
        },
        {
          prop: 'tenantJd',
          label: '所在街道',
          minWidth: '160px',
          formatter: formatText
        },
        {
          prop: 'operatingUnit',
          label: '运营主体单位',
          minWidth: '160px',
          formatter: (row, prop) => {
            let oBtn;
            if (row.operatingUnit && row.operatingUnit != '无') {
              if (row.operatingUnitPic) {
                oBtn = `<span class="operaTxt">${row.operatingUnit}</span>
              <span
                class="look"
              >查看资料</span>
              `;
              } else {
                oBtn = `<span class="operaTxt">${row.operatingUnit}</span>
              <span
                class="noLook"
              >暂无资料</span>
              `;
              }
            } else {
              oBtn = `<span class="operaTxt">${row.operatingUnit || '无'}</span>`;
            }
            return createHTMLVNode(this, oBtn, row, prop, {
              color: '#606266',
              cb: (e, row) => {
                if (row.operatingUnit && row.operatingUnit != '无' && row.operatingUnitPic) {
                  this.zhuanData = {
                    zhuanTitle: '',
                    zhuanFlag: '',
                    content: '',
                    img: '',
                    file: ''
                  };
                  this.zhuanData.zhuanFlag = true;
                  this.zhuanData.zhuanTitle = '运营主体单位';
                  this.zhuanData.img = row.operatingUnitPic;
                }
              }
            });
          }
        },
        {
          prop: 'operatingUnitType',
          label: '运营主体单位运营类型',
          headerTip: '选择：<div>1、综合运营</div><div>2、社区自运营</div>',
          minWidth: '160px',
          formatter: (row, prop) => row.operatingUnitType || '无'
        },
        {
          prop: 'operatingUnitTypePoint',
          label: '运营主体单位运营类型得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'specialOperatingUnitOld',
          label: '专项运营单位（一老）',
          minWidth: '160px',
          formatter: (row, prop) => {
            let oBtn;
            if (row.specialOperatingUnitOld && row.specialOperatingUnitOld != '无') {
              if (row.specialOperatingUnitOldPic) {
                oBtn = `<span class="operaTxt">${row.specialOperatingUnitOld}</span>
              <span
                class="look"
              >查看资料</span>
              `;
              } else {
                oBtn = `<span class="operaTxt">${row.specialOperatingUnitOld}</span>
              <span
                class="noLook"
              >暂无资料</span>
              `;
              }
            } else {
              oBtn = `<span class="operaTxt">${row.specialOperatingUnitOld || '无'}</span>`;
            }
            return createHTMLVNode(this, oBtn, row, prop, {
              color: '#606266',
              cb: (e, row) => {
                if (row.specialOperatingUnitOld && row.specialOperatingUnitOld != '无' && row.specialOperatingUnitOldPic) {
                  this.zhuanData = {
                    zhuanTitle: '',
                    zhuanFlag: '',
                    content: '',
                    img: '',
                    file: ''
                  };
                  this.zhuanData.zhuanFlag = true;
                  this.zhuanData.zhuanTitle = '专项运营单位（一老）';
                  this.zhuanData.content = row.specialOperatingUnitOldContent;
                  this.zhuanData.img = row.specialOperatingUnitOldPic;
                  this.zhuanData.file = row.specialOperatingUnitOldFile;
                }
              }
            });
          }
        },
        {
          prop: 'specialOperatingUnitOldPoint',
          label: '专项运营单位（一老）得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'specialOperatingUnitYoung',
          label: '专项运营单位（一小）',
          minWidth: '160px',
          formatter: (row, prop) => {
            let oBtn;
            if (row.specialOperatingUnitYoung && row.specialOperatingUnitYoung != '无') {
              if (row.specialOperatingUnitYoungPic) {
                oBtn = `<span class="operaTxt">${row.specialOperatingUnitYoung}</span>
              <span
                class="look"
              >查看资料</span>
              `;
              } else {
                oBtn = `<span class="operaTxt">${row.specialOperatingUnitYoung}</span>
              <span
                class="noLook"
              >暂无资料</span>
              `;
              }
            } else {
              oBtn = `<span class="operaTxt">${row.specialOperatingUnitYoung || '无'}</span>`;
            }
            return createHTMLVNode(this, oBtn, row, prop, {
              color: '#606266',
              cb: (e, row) => {
                if (row.specialOperatingUnitYoung && row.specialOperatingUnitYoung != '无' && row.specialOperatingUnitYoungPic) {
                  this.zhuanData = {
                    zhuanTitle: '',
                    zhuanFlag: '',
                    content: '',
                    img: '',
                    file: ''
                  };
                  this.zhuanData.zhuanFlag = true;
                  this.zhuanData.zhuanTitle = '专项运营单位（一小）';
                  this.zhuanData.content = row.specialOperatingUnitYoungContent;
                  this.zhuanData.img = row.specialOperatingUnitYoungPic;
                  this.zhuanData.file = row.specialOperatingUnitYoungFile;
                }
              }
            });
          }
        },
        {
          prop: 'specialOperatingUnitYoungPoint',
          label: '专项运营单位（一小）得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        // {
        //   prop: "isFinshOldOrganization",
        //   label: "养老机构数据上报",
        //   minWidth: "160px",
        //   sortable: true,
        //   headerTip: `选择：<div>1、无</div><div>2、系统上报</div><div>3、手动填报</div>`,
        //   formatter: (row, prop) => {
        //     let oBtn;
        //     if (row.isFinshOldOrganization == "是") {
        //       oBtn = `<span>${row.isFinshOldOrganization}</span>
        //       <img src=${require("./img/eye.png")} class="eye"/>
        //       `;
        //     } else {
        //       oBtn = `<span>${row.isFinshOldOrganization}</span>`;
        //     }
        //     return createHTMLVNode(this, oBtn, row, prop, {
        //       color: "#606266",
        //       cb: (e, row) => {
        //         if (row.isFinshOldOrganization == "是") {
        //           this.serviceTitle = "养老机构数据上报";
        //           this.serviceTime = 0;
        //           this.serviceFlag = true;
        //         }
        //       },
        //     });
        //   },
        // },
        // {
        //   prop: "isFinshYoungOrganization",
        //   label: "托育机构数据上报",
        //   minWidth: "160px",
        //   sortable: true,
        //   headerTip: `选择：<div>1、无</div><div>2、系统上报</div><div>3、手动填报</div>`,
        //   formatter: (row, prop) => {
        //     let oBtn;
        //     if (row.isFinshYoungOrganization == "是") {
        //       oBtn = `<span>${row.isFinshYoungOrganization}</span>
        //       <img src=${require("./img/eye.png")} class="eye"/>
        //       `;
        //     } else {
        //       oBtn = `<span>${row.isFinshYoungOrganization}</span>`;
        //     }
        //     return createHTMLVNode(this, oBtn, row, prop, {
        //       color: "#606266",
        //       cb: (e, row) => {
        //         if (row.isFinshYoungOrganization == "是") {
        //           this.serviceTitle = "托育机构数据上报";
        //           this.serviceTime = 0;
        //           this.serviceFlag = true;
        //         }
        //       },
        //     });
        //   },
        // },
        // {
        //   prop: "isFinshCanteenOrganization",
        //   label: "社区食堂数据上报",
        //   minWidth: "160px",
        //   sortable: true,
        //   headerTip: `选择：<div>1、无</div><div>2、系统上报</div><div>3、手动填报</div>`,
        //   formatter: (row, prop) => {
        //     let oBtn;
        //     if (row.isFinshCanteenOrganization == "是") {
        //       oBtn = `<span>${row.isFinshCanteenOrganization}</span>
        //       <img src=${require("./img/eye.png")} class="eye"/>
        //       `;
        //     } else {
        //       oBtn = `<span>${row.isFinshCanteenOrganization}</span>`;
        //     }
        //     return createHTMLVNode(this, oBtn, row, prop, {
        //       color: "#606266",
        //       cb: (e, row) => {
        //         if (row.isFinshCanteenOrganization == "是") {
        //           this.serviceTitle = "社区食堂数据上报";
        //           this.serviceTime = 0;
        //           this.serviceFlag = true;
        //         }
        //       },
        //     });
        //   },
        // },
        {
          prop: 'oldOrganizationData',
          label: '养老机构数据',
          minWidth: '200px',
          headerTip: `各项目根据实际情况上报相关数据，参考数据：<div>1、机构床位总数</div><div>2、剩余床位数</div><div>3、本社区养老床位占比：本社区老人使用床位数/使用中总床位数</div>`,
          formatter: (row, prop) => {
            let oBtn = ``;
            if (row.oldOrganizationData) {
              oBtn = ['', '未贯通'].includes(row.oldOrganizationData.trim()) ? '无' : row.oldOrganizationData.replace(/\n/g, '<br/>');
            } else {
              oBtn = '无';
            }
            return createHTMLVNode(this, oBtn, row, prop, {
              color: '#606266',
              cb: (e, row) => {}
            });
          }
        },
        {
          prop: 'oldOrganizationDataPoint',
          label: '养老机构数据得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'youngOrganizationData',
          label: '托育机构数据',
          minWidth: '240px',
          headerTip: `各项目根据实际情况上报相关数据，参考数据：<div>1、机构托位总数</div><div>2、剩余托位数</div><div>3、本社区幼儿托育人数占比：当前本社区托育人数/当前总托育人数</div><div>4、托育服务总次数</div><div>5、近30日托育服务次数</div>`,
          formatter: (row, prop) => {
            let oBtn = ``;
            if (row.youngOrganizationData) {
              oBtn = ['', '未贯通'].includes(row.youngOrganizationData.trim()) ? '无' : row.youngOrganizationData.replace(/\n/g, '<br/>');
            } else {
              oBtn = '无';
            }
            return createHTMLVNode(this, oBtn, row, prop, {
              color: '#606266',
              cb: (e, row) => {}
            });
          }
        },
        {
          prop: 'youngOrganizationDataPoint',
          label: '托育机构数据得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'canteenOrganizationData',
          label: '社区食堂数据',
          minWidth: '350px',
          headerTip: `各项目根据实际情况上报相关数据，参考数据：<div>1、总用餐数</div><div>2、本社区居民总用餐数</div><div>3、本社区老人（60岁及以上）总用餐数</div><div>4、近30日用餐数</div><div>5、近30日本社区居民用餐数</div><div>6、近30日本社区老人（60岁及以上）用餐数</div>`,
          formatter: (row, prop) => {
            let oBtn = ``;
            if (row.canteenOrganizationData) {
              oBtn = ['', '未贯通'].includes(row.canteenOrganizationData.trim())
                ? '无'
                : row.canteenOrganizationData.replace(/\n/g, '<br/>');
            } else {
              oBtn = '无';
            }
            return createHTMLVNode(this, oBtn, row, prop, {
              color: '#606266',
              cb: (e, row) => {}
            });
          }
        },
        {
          prop: 'canteenOrganizationDataPoint',
          label: '社区食堂数据得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'personRoomPenetrationMatchingRate',
          label: '人房贯通匹配率',
          minWidth: '160px',
          sortable: true,
          sortMethod: (a, b) => {
            return judgePercent(a.personRoomPenetrationMatchingRate, b.personRoomPenetrationMatchingRate);
          },
          headerTip: `基础数据中，有人员信息的房屋数/总房屋数`,
          formatter: formatText
        },
        {
          prop: 'personRoomPenetrationMatchingRatePoint',
          label: '人房贯通匹配率得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'allRoomCount',
          label: '总房屋数（套）',
          minWidth: '160px',
          sortable: true,
          headerTip: `基础数据中，房屋总数`,
          formatter: formatText
        },
        {
          prop: 'tenantAllPersonCount',
          label: '社区总人数',
          minWidth: '160px',
          sortable: true,
          headerTip: `基础数据中，社区总人数`,
          formatter: formatText
        },
        {
          prop: 'registerPersonCount',
          label: '注册人数',
          minWidth: '160px',
          sortable: true,
          headerTip: `完成账号注册并绑定真实房号的人数`,
          formatter: formatText
        },

        {
          prop: 'registerRoomCount',
          label: '注册房屋数',
          minWidth: '160px',
          sortable: true,
          headerTip: `基础数据中，有小程序注册人员的房屋数`,
          formatter: formatText
        },
        {
          prop: 'registerRoomMatchingRate',
          label: '注册房屋覆盖率',
          minWidth: '160px',
          sortable: true,
          sortMethod: (a, b) => {
            return judgePercent(a.registerRoomMatchingRate, b.registerRoomMatchingRate);
          },
          headerTip: `基础数据中，有小程序注册用户的房屋数/总房屋数`,
          formatter: formatText
        },
        {
          prop: 'registerRoomMatchingRatePoint',
          label: '注册房屋覆盖率得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'newEnrollment',
          label: '近30日新增注册人数',
          minWidth: '160px',
          sortable: true,
          headerTip: `近30日中，新增注册人数（需绑定真实房号）`,
          formatter: formatText
        },
        {
          prop: 'activePopulation',
          label: '近30日活跃人数',
          minWidth: '160px',
          sortable: true,
          headerTip: `近30日中，总活跃注册人数(相同用户只计算一次)`,
          formatter: formatText
        },
        {
          prop: 'activeRate',
          label: '近30日活跃率',
          minWidth: '160px',
          sortable: true,
          sortMethod: (a, b) => {
            return judgePercent(a.activeRate, b.activeRate);
          },
          headerTip: `近30日中，总活跃注册人数(相同用户只计算一次)/总注册人数`,
          formatter: formatText
        },
        {
          prop: 'activeRatePoint',
          label: '近30日活跃率得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'silentNumber',
          label: '超过90日的沉默人数',
          minWidth: '160px',
          sortable: true,
          headerTip: `超过90天没有日活数据的注册人数`,
          formatter: formatText
        },
        {
          prop: 'silentRate',
          label: '超过90日的沉默率',
          minWidth: '160px',
          sortable: true,
          sortMethod: (a, b) => {
            return judgePercent(a.silentRate, b.silentRate);
          },
          headerTip: `超过90天没有日活数据的注册人数/总注册人数`,
          formatter: formatText
        },
        {
          prop: 'silentRatePoint',
          label: '超过90日的沉默率得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'averageClicks',
          label: '近30日平均点击量',
          minWidth: '160px',
          sortable: true,
          headerTip: `近30日中，注册人数的总点击量/30天`,
          formatter: formatText
        },
        {
          prop: 'averageActivePopulation',
          label: '近30日平均日活人数',
          minWidth: '160px',
          sortable: true,
          headerTip: `近30日中，总活跃注册人数(相同用户同一天只计算一次)/30天`,
          formatter: formatText
        },
        {
          prop: 'averageActivePopulationPoint',
          label: '近30日平均日活人数得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'appClickRanking',
          label: '近30日应用点击排行（前三）',
          minWidth: '160px',
          headerTip: `近30日中，点击量排行前三的应用`,
          formatter: formatText
        },
        {
          prop: 'activityNewPeopleNumber',
          label: '近30日活动同比新增人数',
          minWidth: '160px',
          sortable: true,
          headerTip: `近30日中参加活动的人中，未在上一个近30日参加活动的人数（相同用户只计算一次）`,
          formatter: formatText
        },
        {
          prop: 'activityNewPeopleNumberPoint',
          label: '近30日活动同比新增人数得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'activityKeepPeopleNumber',
          label: '近30日活动同比留存人数',
          minWidth: '160px',
          sortable: true,
          headerTip: `近30日中参加活动的人中，在上一个近30日也参加活动的人数（相同用户只计算一次）`,
          formatter: formatText
        },
        {
          prop: 'activityKeepPeopleNumberPoint',
          label: '近30日活动同比留存人数得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'videoMonitoredDevices',
          label: '视频监控设备数',
          minWidth: '160px',
          headerTip: `根据省平台设备挂接情况分场景上报设备数量，未挂接相关设备的填写【0台】`,
          formatter: (row, prop) => {
            const htmlStr = row[prop]
              ? row[prop]
                  .split('\n')
                  .map(item => `<div>${item}</div>`)
                  .join('')
              : '无';
            return createHTMLVNode(this, htmlStr, row, prop, {
              color: '#606266'
            });
          }
        },
        {
          prop: 'videoMonitoredDevicesPoint',
          label: '视频监控设备数得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'passengerFlowData',
          label: '近30日客流数据',
          minWidth: '160px',
          headerTip: `根据省平台设备挂接情况分场景上报客流数量，同一类场景多台设备的情况下，数据合计上报。未挂接设备的，填写【无设备】`,
          formatter: (row, prop) => {
            const htmlStr = row[prop]
              ? row[prop]
                  .split('\n')
                  .map(item => `<div>${item}</div>`)
                  .join('')
              : '无';
            return createHTMLVNode(this, htmlStr, row, prop, {
              color: '#606266'
            });
          }
        },
        {
          prop: 'passengerFlowDataPoint',
          label: '近30日客流数据得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'entranceGuardPassingCount',
          label: '近30日浙住通门禁通行数',
          minWidth: '160px',
          headerTip: `上报社区中浙住通门禁的通行次数。未配备浙住通门禁的填写【无设备】`,
          formatter: formatText
        },
        {
          prop: 'entranceGuardPassingCountPoint',
          label: '近30日浙住通门禁通行数得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'deviceOfflineCount',
          label: '设备离线数',
          minWidth: '160px',
          sortable: true,
          headerTip: `社区物联网离线设备数量`,
          formatter: formatText
        },
        {
          prop: 'deviceOnlineRate',
          label: '设备在线率',
          minWidth: '160px',
          sortable: true,
          sortMethod: (a, b) => {
            return judgePercent(a.deviceOnlineRate, b.deviceOnlineRate);
          },
          headerTip: `社区物联网在线设备数量/社区物联网设备总数`,
          formatter: formatText
        },
        {
          prop: 'deviceOnlineRatePoint',
          label: '设备在线率得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        {
          prop: 'allPoints',
          label: '综合得分',
          minWidth: '160px',
          formatter: (row, prop) => row[prop] || '0'
        },
        // {
        //   prop: 'pointsRate',
        //   label: '得分率',
        //   minWidth: '160px',
        //   formatter: (row, prop) => row[prop] || '0'
        // },
        {
          prop: 'updateTime',
          label: '数据更新时间',
          minWidth: '160px',
          sortable: true,
          formatter: formatText
        }
      ],
      exportLoading: false,
      initTenantQxData: [],
      initYspcData: [],
      tenantQxData: {},
      tenantJdData: []
    };
  },
  components: {},
  created() {
    this.getWlsqOpt();
    this.getInitTenantQx();
    this.getInitYspc();
  },
  mounted() {},
  computed: {},
  methods: {
    changeTenantQxData() {
      this.tenantJdData = [];
      this.searchParam.tenantJd = undefined;
      this.tenantJdData = this.tenantQxData[this.searchParam.tenantQx]
        ? this.tenantQxData[this.searchParam.tenantQx].map(item => ({
            label: item,
            value: item
          }))
        : [];
    },
    async getInitTenantQx() {
      let res = await this.$axios.get(initTenantQxUrl, {});
      if (res && res.code === 200) {
        // res.data = Array.isArray(res.data) ? res.data : [];
        this.tenantQxData = res.data;
        if (res.data) {
          this.initTenantQxData = Object.keys(res.data).map(item => ({
            label: item,
            value: item
          }));
        }
      }
    },
    async getInitYspc() {
      let res = await this.$axios.get(initYspcUrl, {});
      if (res && res.code === 200) {
        res.data = Array.isArray(res.data) ? res.data : [];
        this.initYspcData = res.data.map(item => ({
          label: item,
          value: item
        }));
      }
    },
    afterReturnList(data) {
      let arr = data;
      let oArr = [
        'zcrs',
        'zcfws',
        'thirtyXzzcrs',
        'zfws',
        'ninetyCmrs',
        'thirtyPjdjl',
        'sqzrs',
        'thirtyHyrs',
        'thirtyPjrh',
        'activityTbxzrs',
        'activityTblcrs',
        'tenantAllPersonCount',
        'registerRoomCount',
        'registerPersonCount',
        'newEnrollment',
        'activePopulation',
        'silentNumber',
        'averageClicks',
        'averageActivePopulation',
        'activityNewPeopleNumber',
        'activityKeepPeopleNumber',
        'deviceOfflineCount'
      ];
      arr.forEach(element => {
        oArr.forEach(ele => {
          if (element[ele]) {
            element[ele] = Number(element[ele]);
          } else {
            element[ele] = 0;
          }
        });
      });
      return arr;
    },
    toLook() {},
    getWlsqOpt() {
      this.$axios
        .get(getYysjWlsqmcOptUrl, {})
        .then(res => {
          if (res && res.code === 200) {
            res.data = Array.isArray(res.data) ? res.data : [];
            // tenantId: "P1C2A2S1D1", tenantName: "金钟社区"
            this.wlsqOptions = res.data.map(item => ({
              label: item,
              value: item
            }));
          }
        })
        .catch(() => {});
    },
    exportOnClick() {
      this.exportLoading = true;
      this.$axios
        .post(`${exportYysjUrl}`, null, {
          params: { ...this.searchParam },
          responseType: 'blob'
        })
        .then(res => {
          let blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          }); // 创建一个类文件对象：Blob对象表示一个不可变的、原始数据的类文件对象
          let fileName = decodeURI(res.headers['content-disposition']); // 设置文件名称,decodeURI：可以对后端使用encodeURI() 函数编码过的 URI 进行解码。encodeURI() 是后端为了解决中文乱码问题
          if (fileName) {
            // 根据后端返回的数据处理文件名称
            fileName = fileName.substring(fileName.indexOf('=') + 1);
          }
          const link = document.createElement('a'); // 创建一个a标签
          link.download = fileName; // 设置a标签的下载属性
          link.style.display = 'none'; // 将a标签设置为隐藏
          link.href = URL.createObjectURL(blob); // 把之前处理好的地址赋给a标签的href
          document.body.appendChild(link); // 将a标签添加到body中
          link.click(); // 执行a标签的点击方法
          URL.revokeObjectURL(link.href); // 下载完成释放URL 对象
          document.body.removeChild(link); // 移除a标签
          this.exportLoading = false;
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    confirm() {}
  }
};
</script>

<style lang="less" scoped>
.opeDashboard-divBox {
  box-sizing: border-box;
  height: 100%;
  .editerZhuan {
    .item {
      margin-bottom: 20px;
      .item-l {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  /deep/ .oTable {
    .cell {
      display: flex;
      align-items: center;
    }
    .vnode-wrapper {
      white-space: wrap;
    }
    .eye {
      width: 20px;
      height: 20px;
      margin-left: 6px;
      vertical-align: middle;
    }
    .operaTxt {
      white-space: pre-wrap;
    }
    .look {
      width: 70px;
      height: 24px;
      text-align: center;
      display: block;
      background: #409eff;
      line-height: 24px;
      color: #fff;
      border-radius: 4px;
      font-size: 14px;
    }
    .noLook {
      width: 70px;
      height: 24px;
      text-align: center;
      display: block;
      font-size: 14px;
      background: #999;
      line-height: 24px;
      color: #fff;
      border-radius: 4px;
    }
  }
}
</style>
